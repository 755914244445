<template>
  <div class="common-layout">
  <el-container>
    <el-header>
      <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          @select="handleSelect"
      >
        <el-menu-item index="0">
          <img
              style="width: 100px"
              src="/smfl.png"
              alt="水木 logo"
          />
        </el-menu-item>
        <div class="flex-grow" />

        <div v-if="user != null">
          <!--搜索框-->

<!--          <router-link to="/select">-->
<!--            <el-menu-item index="1">查询</el-menu-item>-->
<!--          </router-link>-->
          <router-link to="/index">
            <el-menu-item index="2">分期数据操作</el-menu-item>
          </router-link>

<!--          <router-link to="item.path">-->
<!--            <el-menu-item index="2"></el-menu-item>-->
<!--          </router-link>-->
          <router-link to="" @click="baodan">
            <el-menu-item index="3">报单</el-menu-item>
          </router-link>


          <router-link to="/" @click="logout">
            <el-menu-item index="3">退出登录</el-menu-item>
          </router-link>
<!--          <router-link to="/quill">-->
<!--            <el-menu-item index="2">富文本测试</el-menu-item>-->
<!--          </router-link>-->


        </div>
        <div v-else>
<!--          <router-link to="/reg">-->
<!--            <el-menu-item index="1">注册</el-menu-item>-->
<!--          </router-link>-->
          <router-link to="/login">
            <el-menu-item index="2">登录</el-menu-item>
          </router-link>
        </div>
      </el-menu>
    </el-header>

    <el-main>
     <router-view />
    </el-main>

  </el-container>
  </div>

  <div class="block text-center">

    <el-carousel height="200px" motion-blur>
      <el-carousel-item v-for="item in 4" :key="item">
        <h3 class="small justify-center" text="2xl">{{ item }}</h3>
      </el-carousel-item>
    </el-carousel>
  </div>

  <div class="bod">
    <h1 class="custom">水木法律业务管理系统</h1>
  </div>

</template>

<script setup>
import { onMounted, ref } from "vue";
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';




const user = ref(localStorage.user ? JSON.parse(localStorage.user) : null);

const router = useRouter();
const route = useRoute();



const menuItems = computed(()=>{
  const userRole = user.value.access;
  const requiresAuth = route.meta.requiresAuth;

  return menuItems.filter(item => {

    return item.roles.includes(userRole)&&(!requiresAuth || userRole === requiresAuth);
  });
});
const logout = () => {
  if (confirm("您确认退出登录吗?")) {
    localStorage.clear(); // 清空记录的用户信息
    user.value = null;
  }
};

const activeIndex = ref('1');

const baodan = () => {
  alert("开发中");
};
</script>

<style scoped>
.flex-grow {
  flex-grow: 1;
}
.el-menu-item {
  display: inline-block; /* 设置 el-menu-item 为内联块级元素 */
}
.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.input-with-select .el-input-group__prepend {
  background-color: var(--el-fill-color-blank);
}
.form-control {
  position: relative;
  margin: 20px 0 40px;
  width: 190px;
}

.form-control input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px #2c3e50 solid;
  display: block;
  width: 100%;
  padding: 15px 0;
  font-size: 18px;
  color: #2c3e50;
}

.form-control input:focus,
.form-control input:valid {
  outline: 0;
  border-bottom-color: lightblue;
}

.form-control label {
  position: absolute;
  top: 15px;
  left: 0;
  pointer-events: none;
}

.form-control label span {
  display: inline-block;
  font-size: 18px;
  min-width: 5px;
  color: #2c3e50;
  transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-control input:focus+label span,
.form-control input:valid+label span {
  color: lightblue;
  transform: translateY(-30px);
}
.search {
  display: flex; /* 使用 flex 布局 */
  align-items: center; /* 垂直居中 */
  position: relative;
}

.search input[type="text"] {
  flex: 1; /* 让输入框自动填充剩余空间 */
  padding: 10px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.search button[type="submit"] {
  background-color: #4e99e9;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: .9s ease;
}

.search button[type="submit"]:hover {
  transform: scale(1.1);
  color: rgb(255, 255, 255);
  background-color: blue;
}


</style>
