import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//导入Element相关内容
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
//全局引入axios和qs框架
import axios from "axios";
window.axios = axios;
import qs from 'qs';
window.qs = qs;
// 引入富文本编辑器
import WangEditor from 'wangeditor';

//定义全局的url
const BASE_URL = 'https://chaigege.cn:8088';
//const BASE_URL = 'https://localhost:8088';
window.BASE_URL = BASE_URL;
//定义全局方法
window.getUser=()=>{
    return localStorage.user?JSON.parse(localStorage.user):null;
}

const app = createApp(App);
//在Vue实例中添加全局属性  可供任意页面中在template标签内访问
app.config.globalProperties.BASE_URL = BASE_URL;
//注册所有图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(ElementPlus);//使用Element框架
app.use(store).use(router).mount('#app')
