import { createRouter, createWebHistory } from 'vue-router'
import { watchEffect } from 'vue';

import HomeView from '../views/HomeView.vue'
import {ref} from "vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/select',
    name: 'select',
    component: () => import('../views/Select.vue'),
    roles: ['1', '2',],
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/Index.vue'),
    roles: ['1'],
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import('../views/RegView.vue'),
    roles: ['1'],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    roles: ['1'],
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/TestView.vue'),
    roles: ['2'],
  },
  {
    path: '/quill',
    name: 'quill',
    component: () => import('../views/wangeditor/test.vue'),
    roles: ['2'],
  },
  {
    path: '/lz',
    name: 'lz',
    component: () => import('../views/lz/ai.vue'),
    roles: ['2'],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => ({ y: 0 }),
  routes:routes,
})



// watchEffect(() => {
//   const user = ref(localStorage.user ? JSON.parse(localStorage.user) : null);
//
//   const userRole = user.value.access;
//
//   const userRoutes = routes.filter(route =>{
//     return userRole.value.includes(route.name);
//   } );
//
//   userRoutes.forEach(route => {
//     if (!router.hasRoute(route.name)) {
//       router.addRoute(route.name, route);
//     }
//   });
// });
export default router
